.services-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px;
  }
  
  .column {
    flex: 1; /* Take up full width of the container */
    margin: 0 10px;
  }
  
  .service-category h3 {
    font-weight: bold;
  }
  
  .service-category ul {
    list-style: none;
    padding: 0;
  }
  

  