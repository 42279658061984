@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;800&family=Montserrat:wght@400;700&family=Poppins:wght@400&display=swap');
$mobile-breakpoint: 768px;

$logo-size: 300px;

$primary-color: #0E7361;
$test-color: #3d9970;
$cream-color: #D5DACA;
$button-color: #9BD1C6;

.landing-background {
  background-color: $cream-color;
}

.logo-img {
  max-width: 300px; 
  height: auto; 
  margin-top: 20px; 
  margin-left: 20px;
}

.menu-item {
  text-decoration: none;
  color: white; 
  text-transform: uppercase;
}

.menu-item:hover {
  text-decoration: underline;
  color: white;
}

.mini-banner {
  text-transform: uppercase;
  font-size: 0.8rem;
  background-color:  rgba(255, 255, 255, 0.3);
}

.btn-appt {
  background-color: $primary-color;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
}

.poppins {
  font-family: 'Poppins', sans-serif;
}

.karla {
  font-family: 'Karla', sans-serif;
}

@media (max-width: 768px) {
  .logo-img {
    margin-left: 0; // Remove margin on smaller devices
  }
}
